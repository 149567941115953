import styled from 'styled-components';

export const FooterContainer = styled.div`
  background: #f2f2f2;
`;

export const FootStyle = styled.div`
  width: 860px;
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
  font-size: 12px;
  line-height: 130%;
  color: #9a9a9a;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    padding: 40px 20px;
  }
`;

export const ImageStyle = styled.img`
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
  opacity: 0.3;
  border: 0;
`;
